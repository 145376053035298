import {
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { LookupService } from '../../services/lookup.service';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'manager-search-formly',
  template: `
  <manager-search [accountId]="(accountId$ | async)" [templateField]="field" style="width: 100%;" (managerSearchValue)="receiveSearchValue($event)"
  (clearSearchValue)="clearValue()"></manager-search>
  `,
  styles: [
    `
      .mat-input-element {
        width: 90%;
      }
      .error {
        font-size: 75%;
        position: absolute;
        margin-top: 8px;
      }
    `,
  ],
})
export class ManagerSearchFormlyComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit {
  constructor(private store: Store) {
    super();
  }

  svc = inject(LookupService);
  accountId$: any;

  receiveSearchValue(event) {

    let ctrlManager = this.form.controls['intake']?.controls['supervisor']?.controls['demographics']
    if (ctrlManager) {
      ctrlManager.controls['firstName'].setValue(event.firstName);
      ctrlManager.controls['lastName'].setValue(event.lastName);
      ctrlManager.controls['contactPhone'].setValue(event.location.phoneNumber);
    }

    let stateLabel = '';
    this.svc.getItemByfieldID('states', event.location.state).subscribe((data) => {
      if (data?.length > 0) {
        stateLabel = data[0].label;
      }
    });

    let ctrl = this.form.controls['intake']?.controls['location']?.controls['work'];
    if (ctrl) {
      ctrl.controls['zip'].setValue(event.location.zip);
      ctrl.controls['city'].setValue(event.location.city);
      ctrl.controls['state']?.setValue(stateLabel);
      ctrl.controls['code'].setValue(event.location.code);
      ctrl.controls['name'].setValue(event.location.name);
      ctrl.controls['localPhone'].setValue(event.location.phoneNumber);
      ctrl.controls['address1'].setValue(event.location.address);
      ctrl.controls['address2'].setValue(event.location.address2);
      ctrl.controls['division']?.setValue(event.location.division);
    }
  }

  clearValue() {

    let ctrlManager = this.form.controls['intake']?.controls['supervisor']?.controls['demographics']
    if (ctrlManager) {
      ctrlManager.controls['firstName'].setValue('');
      ctrlManager.controls['lastName'].setValue('');
      ctrlManager.controls['contactPhone'].setValue('');
    }

    let ctrl = this.form.controls['intake']?.controls['location']?.controls['work'];
    if (ctrl) {
      ctrl.controls['zip'].setValue('');
      ctrl.controls['city'].setValue('');
      ctrl.controls['state']?.setValue('');
      ctrl.controls['code'].setValue('');
      ctrl.controls['name'].setValue('');
      ctrl.controls['localPhone'].setValue('');
      ctrl.controls['address1'].setValue('');
      ctrl.controls['address2'].setValue('');
      ctrl.controls['division']?.setValue('');
    }

  }

  ngOnInit() {
      this.accountId$ = this.store.pipe(
        map((data: any) => data?.session?.case?.accountId)
      );
    }
}
