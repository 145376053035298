<div class="custom-select-wrapper" 
    [ngClass]="{ 'disabled-field': templateField?.props?.disabled }">
  <mat-label> Search Home Address </mat-label>

  <mat-form-field
    fxFlex="100"
    style="width: 100%"
    appearance="fill"
    class="dropdown-field"
  >
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="addressControl"
      autobotForceSelection
      (keyup)="onKeyup($event)"
      [disabled]="templateField?.props?.disabled"
    />
    <a
      *ngIf="addressControl?.value"
      matSuffix
      aria-label="Clear"
      (click)="clear()"
    >
      <mat-icon style="font-size: 12px; height: 12px">close</mat-icon>
    </a>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
      <mat-option
        *ngFor="let opt of lookupResults$ | async"
        [value]="opt.label"
        (onSelectionChange)="onSelectionChange(opt)"
        [title]="opt.label"
      >
        {{ opt.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
