import { Account } from '@accounting/common';
import { Assembly } from '@forms/common';
import {
  AccountConfigs,
  Call,
  CaseEvent,
  CaseMetaData,
  CaseSearchResult,
  CaseType,
  Helpers,
  Note,
  Protocols,
} from '@nfr/common';
import { createReducer, on } from '@ngrx/store';
import { CaseActions, CaseApiActions, SessionActions } from '../actions';
import { CaseApiService } from '../services/case-api.service';

export const caseFeatureKey = 'case';

export interface State {
  id?: string;
  claimantFirstName?: string;
  claimantLastName?: string;
  referenceNumber?: string;
  caseType?: string;
  account?: Account;
  assembly?: Assembly;
  model?: any;
  notes: Note[];
  protocols: Protocols[];
  calls: Call[];
  casesearch: CaseSearchResult[];
  events: CaseEvent[];
  status: string;
  props?: { [key: string]: any };
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  lastSaved?: Date;
  error?: any;
  metaData?: CaseMetaData;
  emergencyCaseButtonAttribute: EmergencyCaseButtonState;
  completing: boolean;
  validCaseFormList: any;
  validFNOLCaseFormList: any;
  validDeathCaseFormList: any;
  validNurseTriageCaseFormList: any;
  accountConfigs: AccountConfigs;
  protocolspecialInstruction: boolean;
}

export interface EmergencyCaseButtonState {
  buttonText?: string;
  buttonToolTipText?: string;
  buttonClass?: string;
}
export const initialState: State = {
  referenceNumber: '',
  caseType: '',
  account: undefined,
  assembly: undefined,
  notes: [],
  protocols: [],
  calls: [],
  events: [],
  casesearch: [],
  status: '',
  props: undefined,
  loaded: false,
  loading: true,
  saved: false,
  saving: false,
  lastSaved: undefined,
  error: undefined,
  metaData: {
    transferredBy: undefined,
    caseType: CaseType.Undefined,
    caseOrigin: undefined,
    disableReportOnlyAndSoughtMedical: undefined,
    isTelemedicineDispositionAvailable: false,
    selectedDisposition: undefined,
    completedBy: undefined,
    createdBy: undefined,
    secondaryGaugeData: undefined,

  },
  emergencyCaseButtonAttribute: {
    buttonText: '911 is OFF',
    buttonToolTipText: 'Press to activate 911',
    buttonClass: '',
  },
  completing: false,
  validCaseFormList: [],
  validFNOLCaseFormList:[],
  validDeathCaseFormList: [],
  validNurseTriageCaseFormList:[],
  accountConfigs: undefined,
  protocolspecialInstruction: false,
};

export const reducer = createReducer(
  initialState,
  on(CaseActions.loadCase, (state, action) => state),
  on(CaseApiActions.loadCaseSuccess, (state, action) => ({
    ...state,
    id: action.obj.id,
    accountId: action.obj.accountId,
    model: action.model,
    claimantFirstName: action.obj.claimant.firstName,
    claimantLastName: action.obj.claimant.lastName,
    referenceNumber: action.obj.caseRef,
    caseType: action.obj.caseType,
    assembly: action.assembly,
    events: action.obj.events,
    status: action.obj.status,
    calls: action.obj.calls,
    notes: action.obj.notes ?? [],
    protocols: action.obj.protocols ?? [],
    metaData: {
      ...state.metaData,
      ...action?.model?.metaData,
    },
    emergencyCaseButtonAttribute:
      action?.model?.metaData?.caseType == CaseType.Emergency
        ? {
            ...state.emergencyCaseButtonAttribute,
            buttonText: '911 is ON',
            buttonToolTipText: 'Press to deactivate 911',
            buttonClass: 'emergency-btn-on',
          }
        : state.emergencyCaseButtonAttribute,
    loaded: true,
    loading: false,
    validCaseFormList: Helpers.getValidCaseFormList(action),
    validFNOLCaseFormList: Helpers.getCaseFormList(action,['eligibility', 'intake', 'provider', 'supplemental']),
  validDeathCaseFormList: Helpers.getCaseFormList(action,['eligibility', 'intake', 'supplemental']),
  validNurseTriageCaseFormList: Helpers.getCaseFormList(action,['eligibility', 'intake', 'subjective assessment',
    'ABCD/911', 'secondary assessment', 'disposition', 'provider', 'intervention', 'tabcq'
    , 'Nurse Triage Conclusion', 'supplemental']),
    completing: action?.model?.metaData?.completing === true,
  })),
  on(CaseApiActions.loadCaseFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
  on(CaseActions.updateCaseModel, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      ...action.data,
    },
  })),
  on(CaseActions.updateCaseModelCustom, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      ...action.data,
      triage: action?.data?.triage ?? state.model.triage,
    },
  })),
  on(CaseActions.updateTriageProtocolModel, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      triage: {
        ...state.model.triage,
        ...action.data,
      },
    },
  })),

  on(CaseActions.saveCase, (state) => ({
    ...state,
    saved: false,
    saving: true,
  })),
  on(CaseActions.saveCaseAndExit, (state) => ({
    ...state,
    saved: false,
    saving: true,
  })),
  on(CaseApiActions.saveCaseSuccess, (state) => ({
    ...state,
    saved: true,
    saving: false,
    lastSaved: new Date(),
  })),
  on(CaseApiActions.saveCaseFailure, (state, action) => ({
    ...state,
    saved: true,
    saving: false,
    err: action.error,
    status: 'Draft',
  })),
  on(CaseActions.completeCase, (state, action) => ({
    ...state,
    status: 'Completed',
    completing: false,
    metaData: {
      ...state.metaData,
      completedBy: action.completedBy,
    },
  })),
  on(CaseActions.updateCompleting, (state) => ({ ...state, completing: true })),
  on(CaseActions.updateValidCaseFormList, (state, action) => ({
    ...state,
    validCaseFormList: action.data,
  })),
  on(CaseActions.updateSecondaryGaugesData, (state, action) => ({
    ...state,
    metaData: {
      ...state.metaData,
      secondaryGaugeData: action.data,
    },
  })),
  on(SessionActions.sessionStarted, (state) => ({ ...initialState })),
  on(SessionActions.sessionEnded, (state) => ({ ...initialState })),
  on(CaseActions.addNote, (state, action) => ({
    ...state,
    notes: [...state.notes, action.note],
  })),
  on(CaseActions.addProtocols, (state, action) => ({
    ...state,
    protocols: [...state.protocols, action.protocols],
    protocolspecialInstruction: action.protocolspecialInstruction,
  })),
  on(CaseActions.removeProtocols, (state, action) => ({
    ...state,
    protocols: state.protocols.filter(
      (item) => item.id !== action.protocols.id
    ),
  })),
  on(CaseActions.removeProtocolsSuccess, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      triage: {
        ...state.model?.triage,
        protocol: CaseApiService.removeTriageAbcdProtocol(
          state.model,
          action.protocols
        ),
      },
      planofcare: {
        ...state.model?.planofcare,
        protocol: CaseApiService.removePlanOfCareProtocol(
          state.model,
          action.protocols
        ),
      },
    },
    protocolspecialInstruction: action.protocolspecialInstruction,
  })),
  on(CaseActions.loadSearchCases, (state, action) => state),
  on(CaseApiActions.loadSearchCaseSuccess, (state, action) => ({
    ...state,
    icasesearch: action.results,
    loaded: true,
    loading: false,
  })),
  on(CaseApiActions.loadSearchCaseFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
  on(CaseActions.activateEmergencyCase, (state, action) => state),
  on(CaseActions.activateEmergencyCaseSuccess, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      eligibility: {
        ...state.model.eligibility,
        triage: {
          ...state.model.eligibility.triage,
          dateOfDeath: null,
          injuryResultInDeath: 'false',
        },
      },
      intake: {
        ...state.model?.intake,
        accident: {
          ...state.model?.intake?.accident,
          details: {
            ...state.model?.intake?.accident?.details,
            escalationCriteriaMet: 'Transported by Ambulance',
          },
        },
      },
      planofcare: {
        ...state.model?.planofcare,
        disposition: {
          ...state.model?.planofcare?.disposition,
          dispositionRecommended: '911',
          directEmployee: 'Immediate',
        },
        provider: {
          ...state.model?.planofcare?.provider,
          medicalProvider: 'Ambulance',
        },
      },
    },
    metaData: {
      ...state.metaData,
      caseType: CaseType.Emergency,
    },
    emergencyCaseButtonAttribute: {
      ...state.emergencyCaseButtonAttribute,
      buttonText: '911 is ON',
      buttonToolTipText: 'Press to deactivate 911',
      buttonClass: 'emergency-btn-on',
    },
    loaded: true,
    loading: false,
  })),
  on(CaseActions.activateEmergencyCaseFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
  on(CaseActions.deActivateEmergencyCase, (state, action) => state),
  on(CaseActions.deActivateEmergencyCaseSuccess, (state, action) => ({
    ...state,
    model: {
      ...state.model,      
      planofcare: {
        ...state.model?.planofcare,
        disposition: {
          ...state.model?.planofcare?.disposition,
          dispositionRecommended: '',          
        },        
      },      
    },
    metaData: {
      ...state.metaData,
      caseType: action?.caseType ?? CaseType.Undefined,
    },
    emergencyCaseButtonAttribute: {
      ...state.emergencyCaseButtonAttribute,
      buttonText: '911 is OFF',
      buttonToolTipText: 'Press to activate 911',
      buttonClass: '',
    },
    loaded: true,
    loading: false,
  })),
  on(CaseActions.activateEmergencyCaseFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
  on(CaseActions.evaluateCaseType, (state, action) => state),
  on(CaseActions.evaluateCaseTypeSuccess, (state, action) => ({
    ...state,
    metaData: {
      ...state.metaData,
      caseType: action?.caseType ?? CaseType.Undefined,
    },
    loaded: true,
    loading: false,
  })),
  on(CaseActions.evaluateCaseTypeFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
  on(CaseActions.enableDisableMenuSuccess, (state, action) => ({
    ...state,
    assembly: {
      ...state.assembly,
      forms: action.forms ?? state.assembly.forms,
    },
    loaded: true,
    loading: false,
  })),
  on(CaseActions.enableDisableMenuFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),

  on(CaseApiActions.saveNoteAPI, (state) => ({
    ...state,
    saved: false,
    saving: true,
  })),
  on(CaseApiActions.saveNoteAPIFailure, (state) => ({
    ...state,
    saved: true,
    saving: false,
    lastSaved: new Date(),
  })),
  on(CaseApiActions.saveNoteAPIFailure, (state, action) => ({
    ...state,
    saved: true,
    saving: false,
    err: action.error,
  })),
  on(CaseActions.loadTelemedicineConfigSuccess, (state, action) => ({
    ...state,
    accountConfigs: {
      ...state.accountConfigs,
      telemedicineConfigs: {
        isTelemedicineEnabled: action?.data?.isTelemedicineEnabled ?? false,
        applicableStateList: action?.data?.applicableStateList ?? [],
      },
    },
    loaded: true,
    loading: false,
  })),
  on(CaseActions.activateTelemedicine, (state) => ({
    ...state,
    metaData: {
      ...state.metaData,
      isTelemedicineDispositionAvailable: true,
    },
  })),
  on(CaseActions.deActivateTelemedicine, (state) => ({
    ...state,
    metaData: {
      ...state.metaData,
      isTelemedicineDispositionAvailable: false,
    },
  })),
  on(CaseActions.applyTelemedicineWorkflowSuccess, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      planofcare: {
        ...state.model?.planofcare,
        provider: action?.provider ?? state?.model?.planofcare?.provider,
      },
    },
    metaData: {
      ...state.metaData,
      selectedDisposition:
        action?.metaData?.selectedDisposition ??
        state?.metaData?.selectedDisposition,
    },
  })),
  on(CaseActions.clearTriageData, (state, action) => ({
    ...state,
    protocols: [],
  })),
  on(CaseActions.clearTriageData, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      triage: {},
      planofcare: {
        ...state.model?.planofcare,
        protocol: {},
      },
    },
  })),
  on(CaseActions.assignCase, (state) => {
    return { ...state };
  }),
  on(CaseActions.assignCaseSuccess, (state,action) => {
    return {
      ...state,
      metaData: {
        ...state.metaData,
        createdBy:
        { 
          firstName: action.user.firstName,
          lastName: action.user.lastName
        },
         
      },
    
      loading: false,
      loaded: true,
      
    };
  }),
  on(CaseActions.assignCaseFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: false,
     
      error: action.error,
    };
  }),
);
