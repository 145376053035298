import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AddressVerificationService, searchAddress } from '@autobot/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'address-verification',
  templateUrl: './address-verification.component.html',
  styleUrls: ['./address-verification.component.scss'],
})
export class AddressVerificationComponent implements OnInit {
  address: string;
  @Input() templateField: any;
  filter$: Observable<searchAddress[]> = of([]);
  addressControl = new FormControl();
  @Output() SearchValue = new EventEmitter<any>();
  @Output() clearSearchValue = new EventEmitter<any>();
  private lookupResultsSubject = new BehaviorSubject<any[]>([]);
  lookupResults$ = this.lookupResultsSubject.asObservable();
  constructor(private service: AddressVerificationService) {}
  ngOnInit() {}
  onKeyup(event: KeyboardEvent) {
    // Ignore arrow keys and enter key
    if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'Enter') {
      this.loadRecords(event);
    }
  }
  loadRecords(event: any) {
    const term = event.target.value;
    if (term.length >= 3) {
      this.service
        .AutocompleteAddress(term)
        .then((data) => {
          this.handleSuccess(data);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }
    else {
      this.lookupResultsSubject.next([]);
    }
  }
  
  handleSuccess(response) {
    const specialCharRegex = /[^a-zA-Z0-9.,\&_\- \#\s]/g;
    const lookupResults: searchAddress[] = response.result.map((lookup) => {
      return {
        label: `${lookup?.streetLine}, ${lookup?.city}, ${lookup?.state}, ${lookup?.zipcode}`,
        address1: lookup?.streetLine?.replace(specialCharRegex, ''),
        address2: lookup?.secondary?.replace(specialCharRegex, ''),
        city: lookup?.city,
        state: lookup?.state,
        zipCode: lookup?.zipcode,
      } as searchAddress;
    });
    this.lookupResultsSubject.next(lookupResults);
  }
  
  handleError(response) {
    console.log(response);
  }

  onSelectionChange(event) {
    if (event?.label) {
      this.SearchValue.emit(event);
    }
  }

  clear() {
    this.addressControl.setValue('');
    this.lookupResultsSubject.next([]);
    this.clearSearchValue.emit();
  }
}
