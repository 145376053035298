import { Component, inject } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { OptionLookupService, Options } from '../services/optionLookup.service';

@Component({
  selector: 'multi-level-dropdown',
  template: `
    <div class="multi-level-dropdown-wrapper"
          [ngClass]="{
            'required-field': props.required,
            'disabled-field': formControl.disabled,
            'valid-field': formControl.valid
          }">
      <mat-label>{{props.label}}</mat-label>
      <mat-form-field appearance="fill" class="dropdown-field">
        <mat-select
          [formControl]="formControl"
          #mySelect
          [(ngModel)]="selectedValue"
          [matMenuTriggerFor]="levelOne"
          #levelOneTrigger="matMenuTrigger"
          (focus)="levelOneTrigger.openMenu()"
          [matTooltip]="selectedValue"
          [matTooltipDisabled]="!selectedValue"
          [required]="props.required ?? false"
        >
          <mat-option *ngFor="let category of selectionList" [value]="category">{{
            category
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-menu #levelOne="matMenu" direction="down" yPosition="below">
        <ng-container *ngFor="let menuItem of optionList">
          <span (mouseleave)="levelOneTrigger.closeMenu()"></span>
          <ng-container>
            <li mat-menu-item [matMenuTriggerFor]="levelTwo" 
            [ngClass]="{'selectedMenu': selectedValue.includes(menuItem.label)}">
              <span>{{ menuItem.label }}</span>
            </li>
            <mat-menu #levelTwo="matMenu">
              <ng-container *ngFor="let child2 of menuItem.children">
                <button
                  mat-menu-item
                  (click)="onDropdownItemSelected(menuItem, child2)"
                  [ngClass]="{'selectedMenu': selectedValue === (menuItem.label + ' - ' + child2.label)}"
                >
                  {{ child2.label }}
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>
      </mat-menu>
      <small *ngIf="formControl.invalid && formControl.touched" class="error-message">
        {{ props.validationMessage }}
      </small>
    </div>

  `,
  styles: [
    `
    .selectedMenu {
      color: black;
      background-color: lightgray;
    }
    
    .multi-level-dropdown-wrapper{
      display: flex;
      flex-direction: column;
    }

    mat-label{
      font-size: 12px;
      color: #212529;
    }
    
    .multi-level-dropdown-wrapper.required-field > mat-label::after{
      content: " *";
      font-size: 12;
      color: #FF0000;
    }

    .multi-level-dropdown-wrapper.disabled-field > mat-label::after, .multi-level-dropdown-wrapper.valid-field > mat-label::after{
      color: #212529;
    }

    .dropdown-field{
      width: 100%;
      max-width: 580px;
    }

    .dropdown-field ::ng-deep .mat-form-field-wrapper .mat-form-field-flex{
      border: 2px solid #999999;
      background-color: #ffffff;
      font-size: 14px;
      transition: border-color 0.3s;
      border-radius: 6px;
    }
    
    .multi-level-dropdown-wrapper .dropdown-field.ng-touched.ng-invalid ::ng-deep .mat-form-field-wrapper .mat-form-field-flex{
      border-color: #FF0000;
    }
    
    .multi-level-dropdown-wrapper.required-field .dropdown-field.ng-invalid ::ng-deep .mat-form-field-wrapper .mat-form-field-flex{
      background-color: #FFF2CC;
    }

    ::ng-deep .mat-menu-panel{
      max-width: 600px !important;
    }

    .disabled-field .dropdown-field ::ng-deep > .mat-form-field-wrapper > .mat-form-field-flex{
      background-color: #E6E6E6;
      border-color: #999999;
    }

    .dropdown-field ::ng-deep > .mat-form-field-wrapper > .mat-form-field-underline{
      display: none;
    }

    .dropdown-field ::ng-deep > .mat-form-field-wrapper > .mat-form-field-underline::before{
      display: none;
    }

    .dropdown-field ::ng-deep .mat-form-field-wrapper .mat-form-field-flex:hover{
      border-color: #7EA6E0;
    }

    .dropdown-field ::ng-deep .mat-form-field-wrapper .mat-form-field-flex:focus{
      border-color: #7EA6E0;
    }

    .error-message{
      color: #FF0000;
      font-size: 10px;
      margin-top: 4px;
    }
 `,
  ],
})
export class multiLevelDropdownType extends FieldType<FieldTypeConfig> {

  public classifications: string[] = [];
  public selectedValue = '';
  public selectionList = [];
  public optionList: Options[] = [];

  svc = inject(OptionLookupService);
  ngOnInit() {
    this.svc.getLookUpByName(this.props.dataHint).subscribe((data: Options[]) => {
      if (!this.formControl.disabled) {
        this.optionList = data;
      }
    });

    if (this.formControl.value) {
      this.selectionList = [];
      this.selectionList.push(this.formControl.value);
      this.selectedValue = this.formControl.value;
    }

  }

  onDropdownItemSelected(menuItem: any, child: any) {
    if (!this.formControl.disabled) {
      let val = `${menuItem.label} - ${child.label}`;
      this.selectionList = [];
      this.selectionList.push(val);
      this.selectedValue = val;
    }
  }

}

export const multiLevelDropdown = {
  name: 'multi-level-dropdown',
  component: multiLevelDropdownType,
};


