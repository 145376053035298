import { Component, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';
import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { MaskService } from 'ngx-mask';
import {FieldTypeConfig} from "@ngx-formly/core";

@Component({
  template: `
  <div class="custom-phone-field"
    [ngClass]="{
      'required-field': props.required,
      'disabled-field': props.disabled || props.readonly,
      'valid-field': formControl.valid
    }">
    <mat-label [matTooltip]="props.toolTip ?? props.description" matTooltipPosition="after">{{props.label}}</mat-label>
     
    <input
      matInput
      [mask]="mask"
      [prefix]="prefix"
      [id]="id"
      [type]="type || 'text'"
      [readonly]="props.readonly || props.disabled"
      [required]="props.required ?? false"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [tabIndex]="props.tabindex"
      [placeholder]="props.placeholder"
      (keydown)="onKeydown($event)"
      [ngClass]="{'invalid-input': formControl.invalid}"
    />
    <div *ngIf="this.formControl.errors?.mask" class="error-messages">
      <div>Incorrect phone format, only this format is allowed: XXX-XXX-XXXX.</div>
    </div>
  </div>
  `,
  styles: [
    `
      .error-messages div{
        padding: 0;
        color: #FF0000;
        font-size: 10px;
        margin-top: 4px; 
      }

      .error-messages{
        position: absolute;
      }
    `,  
  ],
})
export class PhoneMaskTypeComponent extends FieldType<FieldTypeConfig> {
  @ViewChild(MatInput) formFieldControl!: MatInput;


  get type() {
    return this.props.type ?? 'text';
  }

  get mask() {
    return this.props.mask ?? '(000) 000-0000';
  }

  get prefix() {
    const pre = this.props.prefix ?? '';

    return pre;
  }
  
  onKeydown(event){
    if(event.key === 'Backspace' || event.key === 'Delete'){
      this.formControl.setValue(null);
      this.formControl.updateValueAndValidity();
    }
  }
}




@Directive({
  selector: 'input[mask]',
})
export class PhoneMaskDirective {
  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly maskService: MaskService,
  ) {}

  @HostListener('keydown', ['$event']) private onKeyDown(
    e: KeyboardEvent
  ): void {
    const el: HTMLInputElement = e.target as HTMLInputElement;
    const position = this.maskService.maskExpression.length;
    const key = e.key || e.keyCode;
    if (key === 'Backspace' || key === 8) {
    } else {
      if (position !== el.value.length) {
        el.setSelectionRange(position, position);
      }
    }
  }
}
