<div class="custom-select-wrapper" style="padding-bottom: 20px;"
      [ngClass]="{ 'disabled-field': templateField?.props?.disabled }">                                               
  <mat-label>
    Manager Search (Store number, first name, last name, store address, store
    phone)
  </mat-label>
  <mat-form-field fxFlex="100" style="width: 100%" appearance="fill" class="dropdown-field">
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="managerControl"
      autobotForceSelection
      (keyup)="onKeyup($event)"
      [disabled]="templateField?.props?.disabled"
    />
    <a
      *ngIf="managerControl?.value"
      matSuffix
      aria-label="Clear"
      (click)="clear()"
    >
      <mat-icon style="font-size: 12px; height: 12px">close</mat-icon>
    </a>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
      <mat-option *ngIf="(filter$ | async)?.length === 0" disabled>
        No items
      </mat-option>
      <mat-option
        *ngFor="let opt of filter$ | async"
        [value]="opt.label"
        (onSelectionChange)="onSelectionChange(opt)"
        [title]="opt.label"
      >
        {{ opt.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
